<template>
    <UCard class="max-w-sm min-w-[350px] w-full bg-white/75 dark:bg-white/5 backdrop-blur">
        <div>
            <RouterLink to="/">
                <CommonLogo class="h-8 w-fit" />
            </RouterLink>
            <h2 class="mt-5 text-2xl font-bold leading-9 tracking-tight text-gray-900 dark:text-white">
                Welcome back
            </h2>
            <p class="text-sm text-neutral-500">
                Don't have an account?
                <NuxtLink prefetch to="/auth/sign-up" class="relative font-medium text-blue-600 group">
                    <span>Sign up</span>
                    <span
                        class="absolute bottom-0 left-0 w-0 group-hover:w-full ease-out duration-300 h-0.5 bg-blue-600">
                    </span>
                </NuxtLink>
            </p>
        </div>

        <div>
            <div class="relative flex flex-wrap w-full h-full">
                <div class="relative w-full max-w-sm lg:mb-0">
                    <div class="relative">
                        <div>
                            <form id="login" @submit.prevent="onSubmit" class="space-y-4">
                                <CommonInput label="Email" placeholder="Email" name="email" />
                                <CommonInput label="Password" placeholder="Password" type="password" name="password" />

                                <div class="flex items-center justify-between">
                                    <div class="flex items-center">
                                        <input id="remember-me" name="remember-me" type="checkbox"
                                            class="h-4 w-4 rounded border-gray-300 text-black-600 focus:ring-black-600" />
                                        <label for="remember-me"
                                            class="ml-3 block text-sm leading-6 text-gray-700">Remember
                                            me</label>
                                    </div>

                                    <div class="text-sm leading-6">
                                        <NuxtLink to="/auth/forgot" class="font-semibold hover:opacity-70">
                                            Forgot password?
                                        </NuxtLink>
                                    </div>
                                </div>

                                <UButton :loading="isSubmitting" type="submit" block trailing
                                    icon="i-tabler-arrow-right">Continue</UButton>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </UCard>

</template>

<script setup lang="ts">
import ApiError from "~/api/models/ApiError";

interface Credentials {
    email: string;
    password: string;
}

const credentials: Credentials = reactive({
    email: "",
    password: "",
});

const { login } = useAuth();

const { handleSubmit, meta, values, isSubmitting, setErrors, errors } = useForm(
    {
        initialValues: credentials,
    }
);

const error = ref<string>("");

const toast = useToast()
const onSubmit = handleSubmit(async (values) => {
    try {
        await login(values.email, values.password);
        toast.add(
            {
                title: 'Hey!',
                description: "You've successfully signed in.",
                ui: {
                    "progress": {
                        "base": 'absolute bottom-0 end-0 start-0 h-1',
                        "background": 'bg-green-300 dark:bg-green-300',
                    }
                },
            });

        closeSignInDialog();
    } catch (apiError) {
        if (apiError instanceof ApiError) {
            setErrors(apiError.errors);
            error.value = apiError.message;
            toast.add(
                {
                    title: 'Oops!',
                    description: apiError.message,
                    ui: {
                        "progress": {
                            "base": 'absolute bottom-0 end-0 start-0 h-1',
                            "background": 'bg-red-300 dark:bg-red-300',
                        }
                    },
                })
        }
    }
});
</script>

<style scoped></style>
